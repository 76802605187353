import React, { useEffect, useState } from 'react'

import * as styles from './InstagramFeed.module.scss'

const InstagramFeed = () => {
  const [instagramPosts, setInstagramPosts] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const result = await fetch('/api/instagram-feed')
          .then(res => res.json())
    
        setInstagramPosts(result)
      } catch(error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <div className={styles.feed}>
      {instagramPosts.slice(0, 5).map((post) => (
        <img key={post.id} src={post.media_url} alt="" />
      ))}
    </div>
  )
}

export default InstagramFeed