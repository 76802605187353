import React from "react";
import { graphql, Link } from "gatsby";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { SelectInput, Textarea, TextInput } from "../components/FormikInputs";
import IconLink from "../components/IconLink";
import Footer from "../components/Layout/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import verifyCaptcha from "../../utils/captcha";
import { useState } from "react";

import * as styles from "../styles/pages/contact-page.module.scss";
import InstagramFeed from "../components/InstagramFeed";
import encode from "../helpers/encode";
import Showcase from "../components/Showcase";
import EventDetails from "../components/Event";

import { insertEnquiry } from "../helpers/database";

const formInitialValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const formValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Email must be valid").required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

export const Head = () => <title>Contact Us | GROWoneAFRICA&trade;</title>;

export default function ContactUsPage({ data }) {
  const { sanityCompanyInfo: companyInfo } = data;
  const {
    concerns
  } = data.sanityContactUsPage
  const events = data.allSanityEvents.nodes;
  const [ buttonActive, setButtonActive ] = useState(true)

  const filteredEvents = events.filter(event => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); 

    const displayFrom = new Date(event.displayFrom);
    const displayUntil = new Date(event.displayUntil);
    displayUntil.setHours(23, 59, 59, 999);
    
    return displayFrom <= now && displayUntil >= today;
  });

  // Get the most recent event
  const mostRecentEvent = filteredEvents[0];


  const replaceEmailsWithMailto = (text) => {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    
    return text.replace(emailRegex, (email) => {
      return `<a href="mailto:${email}">${email}</a>`;
    });
  };

  const handleCaptchaChange = async (token) => {
    // Save the token or use it in your form submission logic
    try {
      const success = await verifyCaptcha(token);
      if (success === 'Success') {
        console.log('CAPTCHA verification successful');
        // You can update the state or take further actions here if needed
        setButtonActive(false)
      } else {
        console.error('CAPTCHA verification failed:');        
        // Handle the case where CAPTCHA verification fails (e.g., show an error message)
      }
    } catch (error) {
      console.error('Error verifying CAPTCHA:', error);
      // Handle any error that might occur during CAPTCHA verification
    }
  };

  const formSubmitHandler = async (values, { resetForm }) => {

    const result = await insertEnquiry(values);

    if (result.success) {
      resetForm();
      alert(
        "Thank you for contacting us. We will try to get back to you as soon as possible."
      );

      // send email
      await fetch('/api/enquiryMail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...values })
      })

    } else {
      alert("An error occurred. Please try again.");
      console.error("Error inserting enquiry:", result.errorMessage);
    }
  };

  return (
    <>
      <main>
        <Showcase image={data.showcaseImage} />

        <div id="next" className="page-container">

          <div className={styles.sectionContainer}>
            <section className={styles.formContainer}>
              <h2>Enquiries</h2>

              <p>Please feel free to contact us with any questions or general enquiries.</p>
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {({ isSubmitting, values }) => (
                  <Form
                    name="contact"
                    // data-netlify="true"
                    // data-netlify-honeypot="bot-field"
                    className={styles.form}
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <TextInput label="Name" placeholder="Name" name="name" type="text" />
                    <TextInput
                      label="Email"
                      placeholder="Email"
                      name="email"
                      type="email"
                    />
                    <SelectInput
                      label="Subject"
                      name="subject"
                      className={values.subject === "" ? styles.placeholder : ""}
                      wrapperClassName="c-span-full"
                    >
                      <option value="" className={styles.placeholder}>
                        Subject
                      </option>
                      <option value="Please send me the Cultivating and Processing information">
                        Please send me the Cultivating and Processing information
                      </option>
                      <option value="Please send me the information to open a GOA Club">
                        Please send me the information to open a GOA Club
                      </option>
                      <option value="Other">Other</option>
                    </SelectInput>
                    <Textarea
                      label="Message"
                      placeholder="Message"
                      name="message"
                      className="c-span-full"
                    />

                    <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={handleCaptchaChange} />

                    <span className="c-span-full">
                      <button
                        className={styles.btnForm}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </span>
                  </Form>
                )}
              </Formik>

              <div className={styles.concernsSection}>
                <h2>Concerns</h2>
                {concerns.map(concern => (
                  <div key={concern._key}>
                    <p><span className={styles.concernBlock} dangerouslySetInnerHTML={{ __html: replaceEmailsWithMailto(concern.children.map(({ text }) => text).join('')) }} /></p>
                  </div>
                ))}
              </div>

              <div className={styles.formContainerBottom}>
                <section className={styles.companyInfo}>
                  <p>
                    <span className={styles.companyName}>{companyInfo.name}</span>
                    <br />
                    {companyInfo.address}
                    <br />
                    {companyInfo.email}
                    <br />
                  </p>
                  <div className={styles.socialIcons}>
                    <IconLink
                      Icon={FaFacebook}
                      title="Facebook"
                      href={companyInfo.socialMediaLinks.facebook}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.icon}
                    />
                    <IconLink
                      Icon={FaInstagram}
                      title="Instagram"
                      href={companyInfo.socialMediaLinks.instagram}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.icon}
                    />
                    <IconLink
                      Icon={FaTiktok}
                      title="TikTok"
                      href="https://www.tiktok.com/@growoneafrica"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.icon}
                    />
                  </div>
                </section>

                <div className={styles.membershipButtons}>
                  <a
                    href={companyInfo.nmaVideoLink}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-primary-lg"
                  >
                    New Membership Video <FaYoutube />
                  </a>
                  <a
                    href={companyInfo.membershipApplicationLink}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-primary-lg"
                  >
                    Membership Application
                  </a>
                  <Link to="/faq/" className="btn-primary">
                    FAQ
                  </Link>
                </div>
              </div>
            </section>

            <section className={styles.eventsContainer}>
              <h2>Events</h2>
              <EventDetails event={mostRecentEvent} />
            </section>

          </div>

          {/* <section className={styles.socialFeed}>
            <h3>
              <a
                href={companyInfo.socialMediaLinks.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook />
                <span className={styles.tag}>growoneafrica</span>
              </a>
              <span className={styles.divider}>|</span>
              <a
                href={companyInfo.socialMediaLinks.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
                <span className={styles.tag}>growoneafrica</span>
              </a>
              <span className={styles.divider}>|</span>
              <a
                href="https://www.tiktok.com/@growoneafrica"
                target="_blank"
                rel="noreferrer"
              >
                <FaTiktok />
                <span className={styles.tag}>@growoneafrica</span>
              </a>
            </h3>

            <InstagramFeed />

            <div className={styles.socialFeedButtons}>
              <a
                href={companyInfo.socialMediaLinks.facebook}
                target="_blank"
                rel="noreferrer"
                className="btn-primary"
              >
                Follow on Facebook
              </a>
              <a
                href={companyInfo.socialMediaLinks.instagram}
                target="_blank"
                rel="noreferrer"
                className="btn-primary"
              >
                Follow on Instagram
              </a>
              <a
                href="https://www.tiktok.com/@growoneafrica"
                target="_blank"
                rel="noreferrer"
                className="btn-primary"
              >
                Follow on TikTok
              </a>
            </div>
          </section> */}
        </div>
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query ContactPageQuery {
    showcaseImage: file(relativePath: { eq: "contact-page/banner.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sanityContactUsPage {
      title
      concerns {
        _key
        _type
        children {
          text
          marks
        }
      }
    }
    sanityCompanyInfo {
      name
      address
      email
      socialMediaLinks {
        facebook
        instagram
      }
      membershipApplicationLink
      nmaVideoLink
    }
    allSanityEvents(sort: {fields: [displayFrom], order: DESC}) {
      nodes {
        id
        title
        description {
          _type
          style
          children {
            _key
            _type
            text
          }
        }
        featuredImage {
          asset {
            url
          }
        }
        showButton
        buttonText
        buttonLink
        eventOccurrences {
          startDate
          endDate
          locationName
        }
        displayFrom
        displayUntil
      }
    }
  }
`;

// Previous form submission logic
  // try {
  //   await fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: encode({
  //       "form-name": "contact",
  //       ...values,
  //     }),
  //   });

  //   resetForm();

  //   alert(
  //     "Thank you for contacting us. We will try to get back to you as soon as possible."
  //   );
  // } catch (error) {
  //   alert("An error occurred. Please try again.");
  //   console.log(error);
  // }

