import React from 'react'
import { useField } from 'formik'

const TextInput = ({ label, className, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <span className={className}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input {...field} {...props} />
      {(meta.touched && meta.error) && (
        <p className='error-message'>{meta.error}</p>
      )}
    </span>
  )
}

const CheckboxInput = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })

  return (
    <span>
      <span className='checkbox-wrapper'>
        <input type='checkbox' {...field} {...props} />
        <label htmlFor={props.id || props.name}>{children}</label>
      </span>
      {(meta.touched && meta.error) && (
        <p className='error-message'>{meta.error}</p>
      )}
    </span>
  )
}

const SelectInput = ({ label, wrapperClassName, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <span className={wrapperClassName}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {(meta.touched && meta.error) && (
        <p className='error-message'>{meta.error}</p>
      )}
    </span>
  )
}

const Textarea = ({ label, className, ...props }) => {
  const [field, meta] = useField(props)
  
  return (
    <span className={className}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea {...field} {...props} />
      {(meta.touched && meta.error) && (
        <p className="error-message">{meta.error}</p>
      )}
    </span>
  )
}

export { TextInput, CheckboxInput, SelectInput, Textarea }