// extracted by mini-css-extract-plugin
export var btnForm = "contact-page-module--btn-form--ba604";
export var companyInfo = "contact-page-module--company-info--e42b7";
export var companyName = "contact-page-module--company-name--cfa97";
export var concernsSection = "contact-page-module--concernsSection--a9363";
export var divider = "contact-page-module--divider--e0775";
export var eventsContainer = "contact-page-module--events-container--f68e6";
export var form = "contact-page-module--form--bb12c";
export var formContainer = "contact-page-module--form-container--ded75";
export var formContainerBottom = "contact-page-module--form-container-bottom--02bd7";
export var icon = "contact-page-module--icon--ed277";
export var membershipButtons = "contact-page-module--membership-buttons--f098f";
export var placeholder = "contact-page-module--placeholder--f6a85";
export var sectionContainer = "contact-page-module--section-container--11ef6";
export var socialFeed = "contact-page-module--social-feed--8b398";
export var socialFeedButtons = "contact-page-module--social-feed-buttons--759c7";
export var socialFeedPosts = "contact-page-module--social-feed-posts--7055f";
export var socialIcons = "contact-page-module--social-icons--57aeb";
export var tag = "contact-page-module--tag--92861";